import store from '@/store';
//import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Mutations } from '@/store/enums/StoreEnums';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/SignIn.vue"),
      },
      {
        path: "/forgot-pass",
        name: "forgot-pass",
        component: () => import("@/views/authentication/ForgotPassword.vue"),
      },
      {
        path: "/resetPassword/:token",
        name: "resetPassword",
        component: () => import("@/views/authentication/ResetPassword.vue"),
      },
      {
        path: "/password-expire-reset",
        name: "ExpirePasswordReset",
        component: () =>
          import("@/views/authentication/ResetExpirePassword.vue"),
      },
      {
        path: "/sign-switch",
        name: "sign-switch",
        component: () => import("@/views/authentication/Switcher.vue"),
      },

      // {
      //   path: "/sign-up",
      //   name: "sign-up",
      //   component: () => import("@/views/authentication/SignUp.vue"),
      // },
    ],
  },

  {
    path: "/entity-organogram",
    name: "entityorganogram",
    component: () => import("@/layout/EntityOrganogram.vue"),
    children: [
      {
        path: "organogramview",
        name: "organogram view",
        component: () => import("@/views/EntityOrganogram/Tree.vue"),
      },
      {
        path: "create-designation",
        name: "createdesignation",
        component: () =>
          import("@/views/EntityOrganogram/CreateDesignation.vue"),
      },
      {
        path: "menu-action-permission",
        name: "menuaction",
        component: () =>
          import("@/views/EntityOrganogram/MenuActionPermission.vue"),
      },
    ],
  },

  {
    path: "/",
    redirect: "/dash",
    component: () => import("@/layout/DashBoard.vue"),
    children: [
      {
        path: "/dash",
        name: "dashboard",
        component: () => import("@/views/DashBoard/Dashboard.vue"),
      },
      // {
      //   path: "/dash-user",
      //   name: "user dashboard",
      //   component: () => import("@/views/DashBoardUser/Dashboard.vue"),
      // },
    ],
  },
  //Association
  {
    path: "/association",
    name: "association",
    component: () => import("@/layout/Association.vue"),
    children: [
      {
        path: "entity-list/",
        name: "entity list",
        component: () => import("@/views/Association/EntityList.vue"),
      },
      {
        path: "entity-list/:id",
        name: "single entity",
        component: () => import("@/views/Association/SingleEntity.vue"),
      },
      {
        path: "entity-employee/:id",
        name: "entity employee",
        component: () => import("@/views/Association/EntityEmployee.vue"),
      },
      {
        path: "employee-profile/:id",
        name: "entityEmployeeProfiles",
        component: () => import("@/views/Employee/EmployeeProfile.vue"),
      },
    ],
  },

  //All over Task Manager
  {
    path: "/tasks",
    name: "tasks",
    component: () => import("@/layout/Tasks.vue"),
    children: [
      {
        path: "all-tasks",
        name: "all tasks",
        component: () => import("@/views/Tasks/allTasks.vue"),
      },
      {
        path: "received-tasks",
        name: "received tasks",
        component: () => import("@/views/Tasks/ReceivedTasks.vue"),
      },
      {
        path: "user-wise-tasks/:id",
        name: "user wise tasks",
        component: () => import("@/views/Tasks/UserWiseTasks.vue"),
      },
      {
        path: "draft-tasks",
        name: "draft tasks",
        component: () => import("@/views/Tasks/DraftTasks.vue"),
      },
      {
        path: "completed-tasks",
        name: "completed tasks",
        component: () => import("@/views/Tasks/CompletedTasks.vue"),
      },
      {
        path: "revert-tasks",
        name: "revert tasks",
        component: () => import("@/views/Tasks/RevertTasks.vue"),
      },
      {
        path: "deadline-tasks",
        name: "deadline tasks",
        component: () => import("@/views/Tasks/DeadlineTasks.vue"),
      },
      {
        path: "task-file-upload",
        name: "task file upload",
        component: () => import("@/views/Tasks/TaskFileUpload.vue"),
      },
      {
        path: "draft-tasks",
        name: "draft tasks",
        component: () => import("@/views/Tasks/DraftTasks.vue"),
      },
      {
        path: "create-task",
        name: "create task",
        component: () => import("@/views/Tasks/CreateTask.vue"),
      },
      // {
      //   path: "all-task",
      //   name: "all task",
      //   component: () => import("@/views/GisUser/AllTask.vue"),
      // },
      {
        path: "assign-qc-rs-file",
        name: "assign QC",
        component: () => import("@/views/Tasks/AssignQC.vue"),
      },
      {
        path: "assign-digitizer",
        name: "assign digitizer",
        component: () => import("@/views/Tasks/AssignDigitizer.vue"),
      },
      {
        path: "assign-qc-digitized-file",
        name: "assign digitizer digitized file",
        component: () => import("@/views/Tasks/AssignQCDigitizedFile.vue"),
      },
      {
        path: "assign-csc",
        name: "assign csc",
        component: () => import("@/views/Tasks/AssignCSC.vue"),
      },
      {
        path: "assign-survey",
        name: "assign suvey",
        component: () => import("@/views/Tasks/AssignSurvey.vue"),
      },
      {
        path: "assign-qc-survey-file",
        name: "assign qc survey file",
        component: () => import("@/views/Tasks/AssignQCSurveyFile.vue"),
      },
      {
        path: "user-wise-complete-tasks",
        name: "user wise complete tasks",
        component: () => import("@/views/Tasks/UserWiseCompleteTask.vue"),
      },
      {
        path: "user-wise-assign-tasks",
        name: "user wise assign tasks",
        component: () => import("@/views/Tasks/UserWiseAssignTask.vue"),
      },
      {
        path: "user-wise-revert-tasks",
        name: "user wise revert tasks",
        component: () => import("@/views/Tasks/UserWiseRevertTask.vue"),
      },
      {
        path: "user-wise-timepassed-tasks",
        name: "user wise timepassed tasks",
        component: () => import("@/views/Tasks/UserWiseTimePassedTask.vue"),
      },
      {
        path: "waiting-approval-tasks",
        name: "waiting approval tasks",
        component: () => import("@/views/Tasks/WaitingApproveFile.vue"),
      },
      {
        path: "approval-tasks",
        name: "approval tasks",
        component: () => import("@/views/Tasks/ApproveFile.vue"),
      },
      {
        path: "waiting-forward-tasks-in-gis",
        name: "waiting forward tasks in gis",
        component: () => import("@/views/Tasks/WaitingForwardTaskInGis.vue"),
      },
      {
        path: "assign-mouza-digitizer",
        name: "Assign Mouza Digitizer",
        component: () => import("@/views/MouzaTasks/AssignDigitizer.vue"),
      },
      {
        path: "assign-qc-shape-file",
        name: "assign qc shape file",
        component: () => import("@/views/MouzaTasks/AssignQCShapeFile.vue"),
      },
      {
        path: "assign-geo-referencer",
        name: "assign geo referencer",
        component: () => import("@/views/MouzaTasks/AssignGeoReferencer.vue"),
      },
      {
        path: "assign-qc-geo-referencer-file",
        name: "assign qc geo referencer file",
        component: () => import("@/views/MouzaTasks/AssignQCGeoReferencerFile.vue"),
      },
      {
        path: "assign-admin-geo-referencer-qc-file",
        name: "assign admin geo referencer qc file",
        component: () => import("@/views/MouzaTasks/AssignAdminGeoReferencerQcFile.vue"),
      },
      {
        path: "assign-geo-referencer-secondary-file",
        name: "assign geo referencer secondary file",
        component: () => import("@/views/MouzaTasks/AssignGeoReferencerSecondaryFile.vue"),
      },
      {
        path: "assign-qc-geo-referencer-secondary-file",
        name: "assign qc geo referencer secondary file",
        component: () => import("@/views/MouzaTasks/AssignQCGeoReferencerSecondaryFile.vue"),
      },
      {
        path: "assign-admin-geo-referencer-qc-secondary-file",
        name: "assign admin geo referencer qc secondary file",
        component: () => import("@/views/MouzaTasks/AssignAdminGeoReferencerQcSecondaryFile.vue"),
      },
    ],
  },

  //General User
  {
    path: "/user",
    component: () => import("@/layout/UserProfile.vue"),
    children: [
      {
        path: "show-profile",
        name: "show profile",
        component: () => import("@/views/user/ShowProfile.vue"),
      },
      {
        path: "update-profile",
        name: "update profile",
        component: () => import("@/views/user/UpdateProfile.vue"),
      },
      {
        path: "change-password",
        name: "change password",
        component: () => import("@/views/user/ChangePassword.vue"),
      },
    ],
  },

   //settings
   {
    path: "/settings",
    component: () => import("@/layout/Settings.vue"),
    children: [
      {
        path: "mod",
        name: "mod",
        component: () =>
          import("@/views/configurations/settings/MessageOfDay.vue"),
      },
    ],
  },

  {
    path: "/geo",
    component: () => import("@/layout/Geo.vue"),
    children: [
      {
        path: "geo-division",
        name: "geo division",
        component: () =>
          import("@/views/configurations/settings/GeoDivision.vue"),
      },
      {
        path: "geo-district",
        name: "geo district",
        component: () =>
          import("@/views/configurations/settings/GeoDistrict.vue"),
      },
      {
        path: "geo-sub-district",
        name: "geo sub district",
        component: () =>
          import("@/views/configurations/settings/GeoSubDistrict.vue"),
      },
      {
        path: "geo-grid",
        name: "geo grid",
        component: () => import("@/views/configurations/settings/GeoGrid.vue"),
      },
      {
        path: "geo-mouza",
        name: "geo mouza",
        component: () => import("@/views/configurations/settings/GeoMouza.vue"),
      },
    ],
  },
  {
    path: "/menu-action",
    component: () => import("@/layout/Menu.vue"),
    children: [
      {
        path: "menu",
        name: "menu",
        component: () => import("@/views/configurations/settings/Menu.vue"),
      },
      {
        path: "action",
        name: "action",
        component: () => import("@/views/configurations/settings/Action.vue"),
      },
    ],
  },
  //entities
  {
    path: "/entities",
    component: () => import("@/layout/Entity.vue"),
    children: [
      {
        path: "entity-type-roles",
        name: "entity type roles",
        component: () =>
          import("@/views/configurations/entities/EntityTypeRoles.vue"),
      },
      {
        path: "entity-type-role-groups",
        name: "entity type role groups",
        component: () =>
          import("@/views/configurations/entities/EntityTypeRoleGroups.vue"),
      },
    ],
  },

  //Employee
  {
    path: "/employee",
    component: () => import("@/layout/Employee.vue"),
    children: [
      {
        path: "new-nid",
        name: "new nid",
        component: () => import("@/views/Employee/AddNewNid.vue"),
      },
      {
        path: "new-employee",
        name: "new employee",
        component: () => import("@/views/Employee/NewEmployee.vue"),
      },
      {
        path: "employee-list",
        name: "employee list",
        component: () => import("@/views/Employee/EmployeeList.vue"),
      },
    ],
  },

  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/Builder.vue"),
      },
      {
        path: "/crafted/pages/profile",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
          },
          {
            path: "projects",
            name: "profile-projects",
            component: () =>
              import("@/views/crafted/pages/profile/Projects.vue"),
          },
          {
            path: "campaigns",
            name: "profile-campaigns",
            component: () =>
              import("@/views/crafted/pages/profile/Campaigns.vue"),
          },
          {
            path: "documents",
            name: "profile-documents",
            component: () =>
              import("@/views/crafted/pages/profile/Documents.vue"),
          },
          {
            path: "connections",
            name: "profile-connections",
            component: () =>
              import("@/views/crafted/pages/profile/Connections.vue"),
          },
          {
            path: "activity",
            name: "profile-activity",
            component: () =>
              import("@/views/crafted/pages/profile/Activity.vue"),
          },
        ],
      },
      {
        path: "/crafted/pages/wizards/horizontal",
        name: "horizontal-wizard",
        component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
      },
      {
        path: "/crafted/pages/wizards/vertical",
        name: "vertical-wizard",
        component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
      },
      {
        path: "/crafted/account",
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/crafted/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
          },
        ],
      },
      {
        path: "/apps/customers/getting-started",
        name: "apps-customers-getting-started",
        component: () => import("@/views/apps/customers/GettingStarted.vue"),
      },
      {
        path: "/apps/customers/customers-listing",
        name: "apps-customers-listing",
        component: () => import("@/views/apps/customers/CustomersListing.vue"),
      },
      {
        path: "/apps/customers/customer-details",
        name: "apps-customers-details",
        component: () => import("@/views/apps/customers/CustomerDetails.vue"),
      },
      {
        path: "/apps/calendar",
        name: "apps-calendar",
        component: () => import("@/views/apps/Calendar.vue"),
      },
      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/drawer-chat",
        name: "apps-drawer-chat",
        component: () => import("@/views/apps/chat/DrawerChat.vue"),
      },
      {
        path: "/crafted/modals/general/invite-friends",
        name: "modals-general-invite-friends",
        component: () =>
          import("@/views/crafted/modals/general/InviteFriends.vue"),
      },
      {
        path: "/crafted/modals/general/view-user",
        name: "modals-general-view-user",
        component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
      },
      {
        path: "/crafted/modals/general/upgrade-plan",
        name: "modals-general-upgrade-plan",
        component: () =>
          import("@/views/crafted/modals/general/UpgradePlan.vue"),
      },
      {
        path: "/crafted/modals/general/share-and-earn",
        name: "modals-general-share-and-earn",
        component: () =>
          import("@/views/crafted/modals/general/ShareAndEarn.vue"),
      },
      {
        path: "/crafted/modals/forms/new-target",
        name: "modals-forms-new-target",
        component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
      },
      {
        path: "/crafted/modals/forms/new-card",
        name: "modals-forms-new-card",
        component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
      },
      {
        path: "/crafted/modals/forms/new-address",
        name: "modals-forms-new-address",
        component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
      },
      {
        path: "/crafted/modals/forms/create-api-key",
        name: "modals-forms-create-api-key",
        component: () =>
          import("@/views/crafted/modals/forms/CreateApiKey.vue"),
      },
      {
        path: "/crafted/modals/wizards/two-factor-auth",
        name: "modals-wizards-two-factor-auth",
        component: () =>
          import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-app",
        name: "modals-wizards-create-app",
        component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-account",
        name: "modals-wizards-create-account",
        component: () =>
          import("@/views/crafted/modals/wizards/CreateAccount.vue"),
      },
      {
        path: "/crafted/widgets/lists",
        name: "widgets-list",
        component: () => import("@/views/crafted/widgets/Lists.vue"),
      },
      {
        path: "/crafted/widgets/statistics",
        name: "widgets-statistics",
        component: () => import("@/views/crafted/widgets/Statistics.vue"),
      },
      {
        path: "/crafted/widgets/charts",
        name: "widgets-charts",
        component: () => import("@/views/crafted/widgets/Charts.vue"),
      },
      {
        path: "/crafted/widgets/mixed",
        name: "widgets-mixed",
        component: () => import("@/views/crafted/widgets/Mixed.vue"),
      },
      {
        path: "/crafted/widgets/tables",
        name: "widgets-tables",
        component: () => import("@/views/crafted/widgets/Tables.vue"),
      },
      {
        path: "/crafted/widgets/feeds",
        name: "widgets-feeds",
        component: () => import("@/views/crafted/widgets/Feeds.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/authentication/Error500.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  //store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
